// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:c7ca6d37-878b-4af3-83ca-c333881ccdb6",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_fFqIS1Sm5",
    "aws_user_pools_web_client_id": "7nrn9o7kp1j9s9fiooe9emcajb",
    "aws_appsync_graphqlEndpoint": "https://a226w7rtcfd63ggwdhpcnodtjy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "photo-albums-20190301194257-hostingbucket-klara",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2unid1b9gjpt.cloudfront.net",
    "aws_user_files_s3_bucket": "klara-assets-klara",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "de3c37a1f38a4778863fa5dce728d5eb",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
