import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import { Connect } from 'aws-amplify-react';
import Amplify, { API, graphqlOperation, Storage, Analytics } from 'aws-amplify';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Accordion, Button, Divider, Form, Grid, Header, Icon, Input, List, Segment, Modal, Container, Checkbox, Dropdown } from 'semantic-ui-react';
import { S3Image } from 'aws-amplify-react';

import aws_exports from './aws-exports';
Amplify.configure(aws_exports);

Analytics.record({ name: 'started' });

function makeComparator(key, order = 'asc') {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

    const aVal = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
    const bVal = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (aVal > bVal) comparison = 1;
    if (aVal < bVal) comparison = -1;

    return order === 'desc' ? (comparison * -1) : comparison
  };
}

const SubscribeToNewAlbums = `
  subscription OnCreateAlbum {
    onCreateAlbum {
      id
      name
    }
  }
`;

class AlbumsList extends React.Component {
  albumItems() {
    return this.props.albums.sort(makeComparator('name')).map(album =>
      <List.Item key={album.id}>
        <NavLink to={`/albums/${album.id}`}>{album.name}</NavLink>
      </List.Item>
    );
  }

  render() {
    return (
      <Segment>
        <Header as='h3'>My Albums</Header>
        <List divided relaxed>
          {this.albumItems()}
        </List>
      </Segment>
    );
  }
}

const ListAlbums = `query ListAlbums {
  listAlbums(limit: 9999) {
      items {
          id
          name
      }
  }
}`;

const GetAlbum = `query GetAlbum($id: ID!, $nextTokenForPhotos: String) {
  getAlbum(id: $id) {
    id
    name
    # NEW: Add members field
    members
    photos(sortDirection: DESC, nextToken: $nextTokenForPhotos) {
      nextToken
      items {
        id
        thumbnail {
          width
          height
          key
        }
        preview {
          width
          height
          key
        }
        fullsize {
          width
          height
          key
        }
      }
    }
  }
}`;

const GetPhoto = `query GetPhoto($id: ID!) {
  getPhoto(id: $id) {
    id
    album {
      id
    }
    bucket
    fullsize {
      width
      height
      key
    }
    thumbnail {
      width
      height
      key
    }
    preview {
      width
      height
      key
    }
  }
}`;

class NewAlbum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albumName: ''
    };
  }

  handleChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.value;
    this.setState(change);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const NewAlbum = `mutation NewAlbum($name: String!) {
      createAlbum(input: {name: $name}) {
        id
        name
      }
    }`;

    const result = await API.graphql(graphqlOperation(NewAlbum, { name: this.state.albumName }));
    console.info(`Created album with id ${result.data.createAlbum.id}`);
  }

  render() {
    return (
      <Segment>
        <Header as='h3'>Add a new album</Header>
        <Input
          type='text'
          placeholder='New Album Name'
          icon='plus'
          iconPosition='left'
          action={{ content: 'Create', onClick: this.handleSubmit }}
          name='albumName'
          value={this.state.albumName}
          onChange={this.handleChange}
        />
      </Segment>
    )
  }
}

class AlbumsListLoader extends React.Component {

  // 2a. NEW: add a onNewAlbum() function 
  // for handling subscription events
  onNewAlbum = (prevQuery, newData) => {
    // When we get data about a new album, 
    // we need to put in into an object 
    // with the same shape as the original query results, 
    // but with the new data added as well
    let updatedQuery = Object.assign({}, prevQuery);
    updatedQuery.listAlbums.items = prevQuery.listAlbums.items.concat([newData.onCreateAlbum]);
    return updatedQuery;
  }

  render() {
    return (
      <Connect
        query={graphqlOperation(ListAlbums)}

        // 2b. NEW: Listen to our 
        // SubscribeToNewAlbums subscription
        subscription={graphqlOperation(SubscribeToNewAlbums)}

        // 2c. NEW: Handle new subscription messages
        onSubscriptionMsg={this.onNewAlbum}
      >

        {({ data, loading, errors }) => {
          if (loading) { return <div>Loading...</div>; }
          if (errors.length > 0) { return <div>{JSON.stringify(errors)}</div>; }
          if (!data.listAlbums) return;

          return <AlbumsList albums={data.listAlbums.items} />;
        }}
      </Connect>
    );
  }
}

class AlbumDetailsLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextTokenForPhotos: null,
      hasMorePhotos: true,
      album: null,
      loading: true
    }
    Analytics.record({ name: 'album.load' });
  }
  async loadMorePhotos() {
    if (!this.state.hasMorePhotos) return;
    this.setState({ loading: true });
    const { data } = await API.graphql(graphqlOperation(GetAlbum, { id: this.props.id, nextTokenForPhotos: this.state.nextTokenForPhotos }));
    let album;
    if (this.state.album === null) {
      album = data.getAlbum;
    } else {
      album = this.state.album;
      album.photos.items = album.photos.items.concat(data.getAlbum.photos.items);
    }
    this.setState({
      album: album,
      loading: false,
      nextTokenForPhotos: data.getAlbum.photos.nextToken,
      hasMorePhotos: data.getAlbum.photos.nextToken !== null
    });
  }
  componentDidMount() {
    this.loadMorePhotos();
  }
  render() {
    return <AlbumDetails loadingPhotos={this.state.loading} album={this.state.album} loadMorePhotos={this.loadMorePhotos.bind(this)} hasMorePhotos={this.state.hasMorePhotos} />;
  }
}

class S3ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      uploadIdx: 0,
      uploadLength: 0
    }
  }
  onChange = async (e) => {
    this.setState({ uploading: true });
    var files = e.target.files;
    this.setState({ uploadLength: files.length });
    for (var i = 0; i < files.length; i++) {
      this.setState({ uploadIdx: i });
      const file = files[i];
      const fileName = uuid();
      const result = await Storage.put(
        fileName,
        file,
        {
          customPrefix: { public: 'uploads/' },
          metadata: { albumid: this.props.albumId, type: file.type }
        }
      );
      console.log('Uploaded file: ' + JSON.stringify(result) + ' ' + file.type);
    }
    this.setState({ uploading: false });
  }
  render() {
    return (
      <div>
        <Form.Button
          onClick={() => document.getElementById('add-image-file-input').click()}
          disabled={this.state.uploading}
          icon='file image outline'
          content={this.state.uploading ? 'Uploading... (' + (this.state.uploadIdx + 1) + '/' + this.state.uploadLength + ')' : 'Add Image'}
        />
        <input
          id='add-image-file-input'
          type="file"
          accept='image/*,video/*'
          onChange={this.onChange}
          style={{ display: 'none' }}
          multiple
        />
      </div>
    );
  }
}

class Lightbox extends Component {
  render() {
    return (
      <Modal
        open={this.props.photo !== null}
        onClose={this.props.onClose}
      >
        <Modal.Content>
          <Container textAlign='center' margin='0px'>
            {
              this.props.photo ?
                <S3Image
                  imgKey={this.props.photo.key.replace('public/', '')}
                  theme={{ photoImg: { maxWidth: '100%' } }}
                  onClick={this.props.onNext}
                /> :
                null
            }
          </Container>
          <Grid columns='three'>
            <Grid.Row>
              <Grid.Column>
                <Button onClick={this.props.onPrev} content='Prev' icon='left arrow' labelPosition='left' />
              </Grid.Column>
              <Grid.Column style={{'text-align': 'center'}}>
                <Button onClick={this.props.onDownload} content='Download' icon='cloud download'/>
              </Grid.Column>
              <Grid.Column>
                <Button onClick={this.props.onNext} content='Next' icon='right arrow' labelPosition='right' style={{ float: 'right' }} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

class AlbumSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = { albumOptions: [] };
  }

  async updateAlbumList() {
    const response = await API.graphql(graphqlOperation(ListAlbums));
    const items = response.data.listAlbums.items;
    var albumOptions = items.map(album => { return { text: album.name, value: album.id } });
    this.setState({ albumOptions: albumOptions });
  }

  componentDidMount() {
    this.updateAlbumList();
  }

  handleChange = (e, b) => {
    this.props.onChange(this, b.value);
  };

  render() {
    return (
      <Dropdown placeholder='Select Album' fluid selection options={this.state.albumOptions} onChange={this.handleChange} />
    );
  }
}

class PhotoMover extends Component {
  handleAlbumSelection = (e, albumId) => {
    this.setState({ selectedAlbum: albumId });
  };

  addPhotosToAlbum = async () => {
    var photoIds = this.props.selectedItems;
    var albumId = this.state ? this.state.selectedAlbum : undefined;

    if (!albumId) return;
    if (!photoIds || photoIds.length <= 0) return;

    console.log("Adding " + photoIds.length + " photos to album " + albumId);

    for (var i = 0; i < photoIds.length; i++) {
      let photoId = photoIds[i];
      let response = await API.graphql(graphqlOperation(GetPhoto, { id: photoId }));
      let photo = response.data.getPhoto;
      let createPhotoInput = {
        bucket: photo.bucket,
        fullsize: photo.fullsize,
        preview: photo.preview,
        thumbnail: photo.thumbnail,
        photoAlbumId: albumId
      }
      const CreatePhoto = `mutation($input: CreatePhotoInput!) {
        createPhoto(input: $input) {
            id
        }
      }`;
      await API.graphql(graphqlOperation(CreatePhoto, { input: createPhotoInput }))
    }
    this.props.onClose();
  }

  render() {
    return (
      <Container style={this.props.style}>
        <AlbumSelector onChange={this.handleAlbumSelection} />
        {
          <Form.Button
            onClick={this.addPhotosToAlbum}
            icon='copy outline'
            content={'Copy to Album'}
          />
        }
      </Container>
    );
  }
}

class PhotosList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPhoto: null,
      checkedPhotos: []
    };
  }

  handlePhotoClick = (id, photo) => {
    this.setState({
      selectedPhotoId: id,
      selectedPhoto: photo
    });
  }

  handleLightboxClose = () => {
    this.setState({
      selectedPhoto: null
    });
  }

  handleCheckbox = (key, value) => {
    var checkedPhotos = this.state.checkedPhotos;
    if (value) {
      if (checkedPhotos.indexOf(key) < 0) {
        checkedPhotos.push(key);
      }
    } else {
      checkedPhotos = checkedPhotos.filter(function (element) {
        return element !== key;
      });
    }
    this.setState({ checkedPhotos: checkedPhotos });
  }

  photoItems() {
    return this.props.photos.map(photo =>
      <div key={photo.id} style={{ display: 'inline-block', 'padding': '2px', 'position': 'relative' }}>
        <S3Image
          key={photo.thumbnail.key}
          imgKey={photo.thumbnail.key.replace('public/', '')}
          onClick={this.handlePhotoClick.bind(this, photo.id, photo.preview)}
        />
        <Checkbox
          style={{
            'position': 'absolute',
            'width': '100%',
            'height': '100%',
            'top': '0px',
            'left': '0px',
            'padding': '5px',
            'display': this.state.checkboxEnabled ? 'inherit' : 'none'
          }}
          onChange={(e, data) => this.handleCheckbox(photo.id, data.checked)}
        />
      </div>
    );
  }

  getPhotoIdx = () => {
    let photoId = this.state.selectedPhotoId
    let photos = this.props.photos
    for (var i = 0; i < photos.length; i++) {
      if (photos[i].id === photoId) {
        return i;
      }
    }
    return -1;
  }

  onDownload = () => {
    Analytics.record({ name: 'photo.download' });
    let currentIdx = this.getPhotoIdx();
    let photo = this.props.photos[currentIdx];
    let fullsize = photo.fullsize;

    Storage.get(fullsize.key, {
      expires: 60,
      level: 'public',
      customPrefix: {
        public: ''
      }
    })
      .then(url => {
        window.open(url, "_blank");
      })
      .catch(err => {
        console.log(err)
      });
  }

  onNext = () => {
    Analytics.record({ name: 'photo.next' });
    let currentIdx = this.getPhotoIdx();
    if (currentIdx < 0) return;
    let idx = currentIdx + 1;
    if (idx >= this.props.photos.length) {
      this.props.loadMorePhotos();
    }
    if (idx >= this.props.photos.length) return;
    let nextPhoto = this.props.photos[idx];
    this.setState({
      selectedPhotoId: nextPhoto.id,
      selectedPhoto: nextPhoto.preview
    })

  }

  onPrev = () => {
    Analytics.record({ name: 'photo.prev' });
    let currentIdx = this.getPhotoIdx();
    if (currentIdx < 0) return;
    let idx = currentIdx - 1;
    if (idx < 0) return;
    let nextPhoto = this.props.photos[idx];
    this.setState({
      selectedPhotoId: nextPhoto.id,
      selectedPhoto: nextPhoto.preview
    })
  }

  render() {
    return (
      <div>
        <Divider hidden />
        <Form.Button
          onClick={() => {
            this.setState({ checkboxEnabled: !this.state.checkboxEnabled });
          }}
          icon='image outline'
          content={this.state.checkboxEnabled ? 'Disable Selection' : 'Enable Selection'}
        />
        <PhotoMover onClose={() => { this.setState({ checkboxEnabled: false }) }} selectedItems={this.state.checkedPhotos} style={{ 'margin-top': '5px', 'display': this.state.checkboxEnabled ? 'inline-block' : 'none' }} />
        <Divider hidden />
        {this.photoItems()}
        <Lightbox onDownload={this.onDownload} onNext={this.onNext} onPrev={this.onPrev} photo={this.state.selectedPhoto} onClose={this.handleLightboxClose} />
      </div>
    );
  }
}

class AlbumDetails extends Component {
  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    if (!this.props.album) return 'Loading album...';
    const { activeIndex } = this.state
    return (
      <Segment>
        <Header as='h3'>{this.props.album.name}</Header>
        <Segment.Group>
          <Segment>
            <Accordion>
              <Accordion.Title index={0} active={activeIndex === 0} onClick={this.handleClick}>
                <Header as='h4'>
                  <Icon name='user circle' />
                  <Header.Content>Members</Header.Content>
                </Header>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <List bulleted>
                  {this.props.album.members && this.props.album.members.map((member) => <List.Item key={member}>{member}</List.Item>)}
                </List>
                <AddUsernameToAlbum albumId={this.props.album.id} />
              </Accordion.Content>
            </Accordion>
          </Segment>
          
        </Segment.Group>
        <S3ImageUpload albumId={this.props.album.id} />
        <PhotosList loadMorePhotos={this.props.loadMorePhotos} photos={this.props.album.photos.items} />
        {
          this.props.hasMorePhotos &&
          <Form.Button
            onClick={this.props.loadMorePhotos}
            icon='refresh'
            disabled={this.props.loadingPhotos}
            content={this.props.loadingPhotos ? 'Loading...' : 'Load more photos'}
          />
        }
      </Segment>
    )
  }
}

class AddUsernameToAlbum extends Component {
  constructor(props) {
    super(props);
    this.state = { username: '' };
  }
  handleChange = (e, { name, value }) => this.setState({ [name]: value })
  handleSubmit = async (event) => {
    event.preventDefault();
    const AddUsernameToAlbum = `
        mutation AddUser($username: String!, $albumId: String!) {
            addUsernameToAlbum(username: $username, albumId: $albumId) {
                id
            }
        }`;
    const result = await API.graphql(graphqlOperation(AddUsernameToAlbum, { username: this.state.username, albumId: this.props.albumId }));
    console.log(`Added ${this.state.username} to album id ${result.data.addUsernameToAlbum.id}`);
    this.setState({ username: '' });
  }
  render() {
    return (
      <Input
        type='text'
        placeholder='Username'
        icon='user plus'
        iconPosition='left'
        action={{ content: 'Add', onClick: this.handleSubmit }}
        name='username'
        value={this.state.username}
        onChange={this.handleChange}
      />
    )
  }
}

class App extends Component {
  render() {
    return (
      <Router>
        <Grid padded>
          <Grid.Column>
            <Route path="/" exact component={NewAlbum} />
            <Route path="/" exact component={AlbumsListLoader} />
            <Route
              path="/albums/:albumId"
              render={() => <div><NavLink to='/'>Back to Albums list</NavLink></div>}
            />
            <Route
              path="/albums/:albumId"
              render={props => <AlbumDetailsLoader id={props.match.params.albumId} />}
            />
          </Grid.Column>
        </Grid>
      </Router>
    );
  }
}

export default withAuthenticator(App, { includeGreetings: true });